<template>
    <div>
        <div class="col-lg-10">
            <div class="row">
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Name</p>
                    <p class="font-poppins-semibold text-white">Alice</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">AI Date</p>
                    <p class="font-poppins-semibold text-white">Alice</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dam Breeding Value</p>
                    <p class="font-poppins-semibold text-white">****</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Vaccination Date</p>
                    <p class="font-poppins-semibold text-white">*******</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Registration Number</p>
                    <p class="font-poppins-semibold text-white">KA12332</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Purchase Value</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dams Milk Fat %</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Source type</p>
                    <p class="font-poppins-semibold text-white">Unit Purchase</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Re Number</p>
                    <p class="font-poppins-semibold text-white">17054</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dehorning Date</p>
                    <p class="font-poppins-semibold text-white">15/01/2021</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dams Protein</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Source</p>
                    <p class="font-poppins-semibold text-white">12345</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Date of Birth</p>
                    <p class="font-poppins-semibold text-white">15/01/2021</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Nose Punching Date</p>
                    <p class="font-poppins-semibold text-white">15/01/2021</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Sire Number</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Unit</p>
                    <p class="font-poppins-semibold text-white">******</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Shed No</p>
                    <p class="font-poppins-semibold text-white">04</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dams Name</p>
                    <p class="font-poppins-semibold text-white">*****</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Sire Name</p>
                    <p class="font-poppins-semibold text-white">*****</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Transfer Date</p>
                    <p class="font-poppins-semibold text-white">54 L</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Breeding Value</p>
                    <p class="font-poppins-semibold text-white">XYZ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dams Number</p>
                    <p class="font-poppins-semibold text-white">12344</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Sire Breed</p>
                    <p class="font-poppins-semibold text-white">45 L</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Old Number</p>
                    <p class="font-poppins-semibold text-white">5423</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Breed</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dam's Breed</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Sire Dam Yield</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Country/State/Region</p>
                    <p class="font-poppins-semibold text-white">1237</p>
                </div>

                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Birth Weight</p>
                    <p class="font-poppins-semibold text-white">300Kg</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dam's Best Lactation Yield</p>
                    <p class="font-poppins-semibold text-white">*****</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Sire Daughters Yield</p>
                    <p class="font-poppins-semibold text-white">******</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Remarks</p>
                    <p class="font-poppins-semibold text-white">Lorem ipsum dolor sit amet, co ipsa
                        ipsum numquam officia placeat tempora!</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">F1 calf or not</p>
                    <p class="font-poppins-semibold text-white">12 Kg</p>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Dam's Lactation Number</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div><div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Projected Yield of Daughter</p>
                    <p class="font-poppins-semibold text-white">45L</p>
                </div><div class="col-lg-3">
                    <btn class="px-4 btn-2">Update</btn>
                </div>
                <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Addition Date</p>
                    <p class="font-poppins-semibold text-white">15/2/2023</p>
                </div> <div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Grand Dam Yield</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div><div class="col-lg-3">
                    <p class="font-poppins-regular text-white">Vaccination Type</p>
                    <p class="font-poppins-semibold text-white">CJ</p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cows-listing'
};
</script>

<style scoped lang="scss">
.btn-2{
    background-color: #417ebd ;
    border: 1px solid white;
    color:white;
    &:hover{
    background-color:lightblue;
     }
}
</style>
